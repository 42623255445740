import {
    BACKEND_API_HOST,
    fetchApi,
    PageContext,
    PaginatedResponse,
} from './utils';

export async function getAllProducts(
    ctx: PageContext | null,
    limit: number = 18,
    pageNumber: number = 0,
    category: string | null = null,
    subscriptionType: string | null = null,
    text: string | null = null,
    productType: string | null = null,
    added: boolean | null = null,
    mobileReady: boolean | null = null,
): Promise<PaginatedResponse<SimpleProductResponse>> {
    return await fetchApi(
        ctx,
        added
            ? `${BACKEND_API_HOST}/api/v1/scripts/search`
            : `${BACKEND_API_HOST}/products`,
        'GET',
        null,
        {
            limit,
            pageNumber,
            category,
            subscriptionType,
            text,
            productType,
            added,
            mobileReady,
        },
        added == true,
    );
}

export async function getProductById(
    ctx: PageContext | null,
    id: string,
): Promise<SimpleProductResponse> {
    return await fetchApi(
        ctx,
        `${BACKEND_API_HOST}/products/byId/${id}`,
        'GET',
        null,
        null,
        false,
    );
}

export async function getProductBySlug(
    ctx: PageContext | null,
    slug: string,
): Promise<SimpleProductResponse> {
    return await fetchApi(
        ctx,
        `${BACKEND_API_HOST}/products/bySlug/${slug}`,
        'GET',
        null,
        null,
        false,
    );
}

export async function getScriptsByAuthor(
    ctx: PageContext | null,
    author: string,
): Promise<SimpleProductResponse[]> {
    return await fetchApi(
        ctx,
        `${BACKEND_API_HOST}/products/scripts/byAuthor?author=${author}`,
        'GET',
        null,
        null,
        false,
    );
}

export async function getMyScripts(
    ctx: PageContext | null,
): Promise<SimpleProductResponse[]> {
    return await fetchApi(ctx, `${BACKEND_API_HOST}/api/v1/scripts/mine`);
}

export async function getAuthoredScripts(
    ctx: PageContext | null,
): Promise<SimpleProductResponse[]> {
    return await fetchApi(ctx, `${BACKEND_API_HOST}/api/v1/scripts/authored`);
}

export async function getMySubscriptions(
    ctx: PageContext | null,
): Promise<SimpleProductSubscriptionResponse[]> {
    const subs = await fetchApi(
        ctx,
        `${BACKEND_API_HOST}/api/v1/scripts/subscriptions/mine`,
    );

    if (subs == null || !Array.isArray(subs)) {
        return [];
    }

    return subs;
}

export async function createUpdateSubscription(
    ctx: PageContext | null,
    id: string,
    paymentGateway: PaymentGateway,
    country: string | null,
): Promise<SubscribeResponse> {
    return await fetchApi(
        ctx,
        `${BACKEND_API_HOST}/api/v1/scripts/${id}/subscribe`,
        'POST',
        {
            instances: 1,
            paymentGateway,
            country,
        },
    );
}

export async function deleteSubscription(
    ctx: PageContext | null,
    id: string,
): Promise<SubscribeResponse> {
    return await fetchApi(
        ctx,
        `${BACKEND_API_HOST}/api/v1/scripts/${id}/unsubscribe`,
        'DELETE',
    );
}

export async function updateProduct(
    ctx: PageContext | null,
    id: string,
    name: string,
    status: ProductStatus,
): Promise<SimpleProductResponse> {
    return await fetchApi(
        ctx,
        `${BACKEND_API_HOST}/api/v1/scripts/${id}/update`,
        'POST',
        {
            name,
            status,
        },
    );
}

export enum ScriptCategory {
    Agility = 'Agility',
    Combat = 'Combat',
    Construction = 'Construction',
    Cooking = 'Cooking',
    Crafting = 'Crafting',
    Farming = 'Farming',
    Firemaking = 'Firemaking',
    Fishing = 'Fishing',
    Fletching = 'Fletching',
    Herblore = 'Herblore',
    Hunter = 'Hunter',
    Magic = 'Magic',
    Minigame = 'Minigame',
    Mining = 'Mining',
    MoneyMaking = 'MoneyMaking',
    Other = 'Other',
    Plugin = 'Plugin',
    Prayer = 'Prayer',
    Quests = 'Quests',
    Ranged = 'Ranged',
    Runecrafting = 'Runecrafting',
    Slayer = 'Slayer',
    Smithing = 'Smithing',
    Thieving = 'Thieving',
    Woodcutting = 'Woodcutting',
}

export interface DistributionScriptResponse {
    id: string;
    name: string;
    description: string;
}
export interface SimpleProductResponse {
    id: string;
    type: ProductType;
    purchaseOptions: SimpleProductPurchaseOption[] | null;
    scriptConfigurations: SimpleScriptConfigurationResponse[];
    name: string;
    slug: string;
    description: string;
    version: string;
    author: string;
    category: ScriptCategory;
    subscribers: number;
    threadUrl: string;
    pageMarkdown: string;
    trialDays: number;
    status: ProductStatus;
    mobileReady: boolean;
    createdAt: Date;
    updatedAt: Date;
}

export enum ProductType {
    Script = 'Script',
    Addon = 'Addon',
    Rank = 'Rank',
}

export enum PaymentGateway {
    Stripe = 'Stripe',
    Paypal = 'Paypal',
    Trial = 'Trial',
}

export enum ProductSubscriptionType {
    Free = 'Free',
    PremiumPass = 'PremiumPass',
    PaidMonthly = 'PaidMonthly',
    PaidLifetime = 'PaidLifetime',
    Trial = 'Trial',
}

export enum ProductStatus {
    Available = 'AVAILABLE',
    NotAvailable = 'NOT_AVAILABLE',
    Private = 'PRIVATE',
    PaidLifetime = 'PaidLifetime',
    Trial = 'Trial',
}

export interface SimpleProductPurchaseOption {
    id: string;
    subscriptionType: ProductSubscriptionType;
    price: number;
}

export enum SubscribeRequestStatus {
    Failed = 'Failed',
    SubscriptionCreated = 'SubscriptionCreated',
    SubscriptionUpdated = 'SubscriptionUpdated',
    PaymentRequired = 'PaymentRequired',
    SubscriptionExists = 'SubscriptionExists',
    TrialExpired = 'TrialExpired',
}

export interface SubscribeResponse {
    status: SubscribeRequestStatus;
    redirectTo: string | null;
    stripeSessionId: string | null;
}

export interface SimpleProductSubscriptionResponse {
    id: string;
    subscriptionType: ProductSubscriptionType;
    product: SimpleProductResponse;
    expired: boolean;
    createdAt: Date;
    updatedAt: Date;
}

export enum OptionType {
    String = 'STRING',
    Integer = 'INTEGER',
    Boolean = 'BOOLEAN',
    Double = 'DOUBLE',
}

export interface SimpleScriptConfigurationResponse {
    id: string;
    name: string;
    description: string | null;
    optionType: OptionType;
    defaultValue: string;
    allowedValues: string[];
    createdAt: Date;
}
