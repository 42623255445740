import { getMe } from './userClient';
import { IncomingMessage, ServerResponse } from 'http';
import React from 'react';
import {
    ProductType,
    SimpleProductResponse,
    SimpleProductSubscriptionResponse,
} from './productsClient';

export const BACKEND_API_HOST =
    process.env.NODE_ENV === 'production'
        ? 'https://api-next.powbot.org'
        : process.env.NEXT_PUBLIC_BACKEND_HOST || 'http://localhost:8080';

export const WEBSITE_HOST =
    process.env.NODE_ENV === 'production'
        ? 'https://powbot.org'
        : 'http://localhost:3005/api';

export interface PaginatedResponse<T> {
    items: T[];
    currentPage: number;
    totalPages: number;
    totalElements: number;
}

export interface PageContext {
    req: IncomingMessage;
    res: ServerResponse;
}

export const NoAuthError = new Error('No user fetched');

export async function fetchApi<T>(
    ctx: PageContext | null,
    url: string,
    method: 'POST' | 'GET' | 'DELETE' = 'GET',
    data: {} | null = null,
    query: {} | null = null,
    requireAuth: boolean = true,
): Promise<T> {
    let fetchedUser = null;
    try {
        fetchedUser = await getMe(ctx);
    } catch (e) {
        if (process.env.NODE_ENV !== 'production') {
            console.error(e);
        }
    }

    if (fetchedUser == null && requireAuth) {
        throw NoAuthError;
    }

    const _url = new URL(url);
    if (query != null) {
        const filteredQuery = Object.keys(query).reduce(
            (acc, curr) =>
                query[curr] != null ? { ...acc, [curr]: query[curr] } : acc,
            {},
        );
        _url.search = new URLSearchParams(filteredQuery).toString();
    }

    let resp = null;
    let headers: any = {
        'Content-type': 'application/json',
    };
    if (fetchedUser) {
        headers = {
            ...headers,
            Authorization: `Bearer ${fetchedUser.jwtToken}`,
        };
    }

    try {
        resp = await fetch(_url.toString(), {
            method,
            headers,
            body:
                data != null && method !== 'GET' ? JSON.stringify(data) : null,
        });
    } catch (e) {
        if (process.env.NODE_ENV !== 'production') {
            console.error(e);
        }
        return null;
    }

    return resp != null ? ((await resp.json()) as T) : null;
}

export function titleCase(stringLike: any): string {
    if (stringLike == null) {
        return null;
    }
    const str = stringLike as string;
    return str.charAt(0).toUpperCase() + str.slice(1);
}

export function lazyImport<
    T extends React.ComponentType<any>,
    I extends { [K2 in K]: T },
    K extends keyof I
>(factory: () => Promise<I>, name: K): I {
    return Object.create({
        [name]: React.lazy(() =>
            factory().then((module) => ({ default: module[name] })),
        ),
    });
}

export function numberWithCommas(n) {
    let parts = n.toString().split('.');
    return (
        parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',') +
        (parts[1] ? '.' + parts[1] : '')
    );
}

export function formatProductPrice(
    product: SimpleProductResponse,
    instanceCount: number = 1,
) {
    if (product.type == ProductType.Script) {
        if (product.purchaseOptions[0].subscriptionType == 'Free') {
            return 'Free';
        }
        if (product.purchaseOptions[0].subscriptionType == 'PremiumPass') {
            return 'VIP';
        }
    }

    var discount = 1.0;
    if (instanceCount >= 50) {
        discount -= 0.6;
    } else if (instanceCount >= 25) {
        discount -= 0.5;
    } else if (instanceCount >= 10) {
        discount -= 0.2;
    } else if (instanceCount >= 5) {
        discount -= 0.1;
    } else if (instanceCount >= 2) {
        discount -= 0.05;
    }

    if (product.type == ProductType.Script) {
        return `Premium ($${(
            (discount * (product.purchaseOptions[0].price * instanceCount)) /
            100
        ).toFixed(2)}/mo)`;
    }

    return `$${(
        (discount * (product.purchaseOptions[0].price * instanceCount)) /
        100
    ).toFixed(2)}`;
}

export function findSubscription(
    subscriptions: SimpleProductSubscriptionResponse[] | null,
    product: SimpleProductResponse,
): SimpleProductSubscriptionResponse | null {
    const subs = (subscriptions || []).filter(
        (s) => s.product.id === product.id,
    );
    let subscription: SimpleProductSubscriptionResponse | null = null;
    if (subs.length > 1) {
        subscription = subs.find((s) => !s.expired);
    }

    if (subscription == null && subs.length > 0) {
        subscription = subs[subs.length - 1];
    }

    return subscription;
}
