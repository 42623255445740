import { NoAuthError, PageContext, WEBSITE_HOST } from './utils';

const AJAX_ME_PATH =
    process.env.NODE_ENV === 'production'
        ? '/community/addons/ajax-me.php'
        : '/community/auth/ajax-me';

export async function getMe(ctx: PageContext): Promise<UserAccount> {
    const resp = await fetch(`${WEBSITE_HOST}${AJAX_ME_PATH}`, {
        method: 'GET',
        headers: {
            'Content-type': 'application/json',
            cookie:
                ctx != null
                    ? ctx.req.headers.cookie
                    : document
                    ? document.cookie
                    : null,
        },
    });

    const data = await resp.json();

    if (!data.success || data.username === 'Guest') {
        throw NoAuthError;
    }

    return data;
}

export interface UserAccount {
    success: boolean;
    userId: number;
    email: string;
    username: string;
    userGroupId: UserGroupId;
    jwtToken: string;
    banned: boolean;
    validating: boolean;
}

export enum UserGroupId {
    ScriptWriterUserGroupId = 13,
    ScriptWriter2UserGroupId = 23,
    ScriptWriter3UserGroupId = 24,
    AdminUserGroupId = 4,
    ModeratorUserGroupId = 6,
    RegisteredUserGroupId = 3,
    DeveloperUserGroupId = 2,
    VipUserGroupId = 15,
    VipXUserGroupId = 25,
}

export function isDeveloper(userGroupId: UserGroupId) {
    return [
        UserGroupId.ScriptWriterUserGroupId,
        UserGroupId.ScriptWriter2UserGroupId,
        UserGroupId.ScriptWriter3UserGroupId,
        UserGroupId.AdminUserGroupId,
        UserGroupId.ModeratorUserGroupId,
        UserGroupId.DeveloperUserGroupId,
    ]
        .map((x) => Number(x))
        .indexOf(userGroupId);
}

export function isVIP(userGroupId: UserGroupId) {
    return [
        UserGroupId.ScriptWriterUserGroupId,
        UserGroupId.ScriptWriter2UserGroupId,
        UserGroupId.ScriptWriter3UserGroupId,
        UserGroupId.AdminUserGroupId,
        UserGroupId.ModeratorUserGroupId,
        UserGroupId.DeveloperUserGroupId,
        UserGroupId.VipUserGroupId,
        UserGroupId.VipXUserGroupId,
    ]
        .map((x) => Number(x))
        .indexOf(userGroupId);
}
